<template>
  <v-dialog v-model="mostrar" content-class="dialog-detalhes-leitura">
    <v-card>
      <v-toolbar color="grey lighten-3" flat dense>
        <i> #{{ leituraCarousel.id || "" }} </i>
        <v-spacer />
        <v-toolbar-title v-if="!esconderCarousel">
          <v-btn
            color="grey darken-2"
            text
            :disabled="!carouselPronto || desabilitarPlayer"
            @click="moverCarousel(true)"
          >
            <v-icon v-text="'mdi-arrow-left-bold'" />
          </v-btn>
          <v-btn
            color="grey darken-2"
            text
            :disabled="!carouselPronto || desabilitarPlayer"
            @click="moverCarousel()"
          >
            <v-icon v-text="'mdi-arrow-right-bold'" />
          </v-btn>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="carregandoCarousel"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn text small @click="mostrar = false">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-row class="mt-1">
          <v-col cols="12" md="8">
            <v-row no-gutters class="justify-center">
              <v-col cols="12" md="9">
                <carousel-imagens-leitura
                  :imagens="leituraCarousel.imagens"
                  :imagens-erro="imagensErro"
                  @erroCarregarImagem="erroCarregarImagem"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <v-row class="fill-height" justify="center">
              <v-col>
                <v-row v-if="!esconderCarousel" no-gutters>
                  <v-col class="text-center">
                    <v-btn
                      icon
                      color="info"
                      :disabled="!carouselPronto || desabilitarPlayer"
                      @click="iniciarPausarCarousel(true)"
                    >
                      <v-icon large v-text="'mdi-arrow-left-drop-circle'" />
                    </v-btn>
                    <v-btn
                      icon
                      color="blue darken-3"
                      :disabled="!carouselPronto"
                      @click="iniciarPausarCarousel()"
                    >
                      <v-icon large v-text="iconePlay" />
                    </v-btn>
                    <v-btn
                      icon
                      color="info"
                      :disabled="!carouselPronto || desabilitarPlayer"
                      @click="iniciarPausarCarousel()"
                    >
                      <v-icon large v-text="'mdi-arrow-right-drop-circle'" />
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="!esconderCarousel" no-gutters>
                  <v-col class="align">
                    <p class="caption ma-0">
                      <strong>Velocidade</strong>
                    </p>
                    <v-slider
                      v-model="velocidade"
                      thumb-label
                      ticks
                      :max="7"
                      :min="2"
                      dense
                      hide-details
                      :disabled="!carouselPronto"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <p
                      class="
                        headline
                        text-center
                        ma-0
                        black--text
                        font-weight-black
                      "
                    >
                      {{ leituraCarousel.placa || "-------" }}
                    </p>
                  </v-col>
                </v-row>

                <v-row
                  :class="{ 'd-none': carregandoImagem || esconderRecorte }"
                  no-gutters
                >
                  <canvas id="canvas" style="width: 100%; max-width: 100%" />
                </v-row>

                <v-row v-if="leituraCarousel" no-gutters>
                  <v-col class="text-center">
                    <p class="subtitle-2 mb-0 mt-3">
                      {{
                        leituraCarousel.data_registro ||
                        "" | data("DD/MM/YYYY HH:mm:ss")
                      }}
                    </p>
                    <p class="subtitle-1 mb-0">
                      {{ leituraCarousel.ponto_captura.descricao || "" }} ({{
                        leituraCarousel.camera.descricao || ""
                      }})
                    </p>
                    <p class="subtitle-1 mb-0">
                      Total de passagens:
                      {{ leituraCarousel.totalLeiturasDaPlaca }}
                    </p>
                    <p class="subtitle-1 mb-0">
                      Tipo do veículo Classifier :
                      <strong>{{ leituraCarousel.tipo_veiculo_ocr }}</strong>
                    </p>
                  </v-col>
                </v-row>

                <dialog-detalhes-leitura-botoes-acoes
                  :leitura="leituraCarousel"
                  @perfil-veiculo="mostrarDialogPerfilVeiculos = true"
                  @editar-placa="mostrarDialogEditarPlaca = true"
                  @criar-ocorrencia="
                    mostrarDialogConfirmacaoCriarOcorrencia = true
                  "
                />

                <v-row v-if="!leituraCarousel.veiculo" class="mt-5">
                  <v-col class="py-0 text-center">
                    <i class="body-2">
                      Informações do cortex não disponíveis
                    </i>
                    <br /><br /><br /><br /><br />
                  </v-col>
                </v-row>
                <v-row v-else class="my-0">
                  <v-col class="text-center">
                    <p
                      class="
                        display-1
                        black--text
                        mb-0
                        font-weight-black
                        text-center
                      "
                    >
                      Dados do veículo
                    </p>
                    <v-divider class="mb-1" />
                    <p class="ma-0 body-1">
                      <b>Tipo: </b>
                      {{ leituraCarousel.veiculo.tipo_veiculo ? leituraCarousel.veiculo.tipo_veiculo.descricao : "" }}
                    </p>
                    <p class="ma-0 body-1">
                      <b>Marca/Modelo: </b>
                      {{
                        leituraCarousel.veiculo.veiculo_modelo && leituraCarousel.veiculo.veiculo_modelo.veiculo_marca ?
                        leituraCarousel.veiculo.veiculo_modelo.veiculo_marca.descricao :
                        ""
                      }} / {{
                        leituraCarousel.veiculo.veiculo_modelo ?
                        leituraCarousel.veiculo.veiculo_modelo.descricao :
                        ""
                      }}
                    </p>
                    <p class="ma-0 body-1">
                      <b>Ano Fab./Modelo: </b> {{ (leituraCarousel.veiculo.ano_fabricacao ?? "") + "/" + (leituraCarousel.veiculo.ano_modelo ?? "") }}
                    </p>
                    <p class="ma-0 body-1">
                      <b>Cor: </b> {{ leituraCarousel.veiculo.cor ? leituraCarousel.veiculo.cor.nome_portugues : "" }}
                    </p>
                    <p class="ma-0 body-1">
                      <b>Cidade: </b>
                      {{
                        leituraCarousel.veiculo.cidade ?
                          leituraCarousel.veiculo.cidade.descricao :
                          ""
                      }}/{{
                        leituraCarousel.veiculo.cidade && leituraCarousel.veiculo.cidade.estado ?
                          leituraCarousel.veiculo.cidade.estado.abreviacao :
                          ""
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <dialog-perfil-veiculo
      :mostrar="mostrarDialogPerfilVeiculos"
      :placa="leituraCarousel.placa"
      @fechar="mostrarDialogPerfilVeiculos = false"
    />

    <dialog-detalhes-leitura-confirmar-ocorrencia
      :mostrar="mostrarDialogConfirmacaoCriarOcorrencia"
      :leitura="leituraCarousel"
      @fechar="mostrarDialogConfirmacaoCriarOcorrencia = false"
    />

    <dialog-detalhes-leitura-editar-placa
      :mostrar="mostrarDialogEditarPlaca"
      :leitura="leituraCarousel"
      @fechar="mostrarDialogEditarPlaca = false"
      @placa-alterada="placaAlterada"
    />
  </v-dialog>
</template>

<script>
import apiLeituras from "@/api/leituras";
import cortarPlaca from "@/utils/cortar-placa";

export default {
  components: {
    DialogPerfilVeiculo: () => import("@/components/dialog/perfil-veiculo/DialogPerfilVeiculo"),
    DialogDetalhesLeituraEditarPlaca: () => import("@/components/dialog/leitura/DialogDetalhesLeituraEditarPlaca"),
    DialogDetalhesLeituraConfirmarOcorrencia: () => import("@/components/dialog/leitura/DialogDetalhesLeituraConfirmarOcorrencia"),
    DialogDetalhesLeituraBotoesAcoes: () => import("@/components/dialog/leitura/DialogDetalhesLeituraBotoesAcoes"),
    CarouselImagensLeitura: () => import("@/components/CarouselImagensLeitura"),
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    esconderCarousel: {
      type: Boolean,
      default: false,
    },
    leitura: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      caminhoImagemLeitura:
        process.env.VUE_APP_URL_SERVIDOR,
      mostrarDialogPerfilVeiculos: false,
      mostrarDialogEditarPlaca: false,
      mostrarDialogConfirmacaoCriarOcorrencia: false,
      carregandoImagem: true,
      imagensErro: [],
      velocidade: 3,
      carregandoCarousel: false,
      carousel: [],
      carouselIndex: null,
      iconePlay: "mdi-play-circle",
      carouselAutomatico: false,
      desabilitarPlayer: false,
      timeoutCarousel: null,
      carouselVoltar: false,
      esconderRecorte: false,
      leituraCarousel: {
        ponto_captura: {},
        camera: {},
      },
    };
  },

  computed: {
    mostrar: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    carouselPronto() {
      return this.carousel.length > 0 && !this.carregandoCarousel;
    },
  },

  watch: {
    carouselAutomatico(val) {
      if (val) {
        this.iconePlay = "mdi-pause-circle";
        this.desabilitarPlayer = true;

        this.moverCarousel(this.carouselVoltar);
      } else {
        this.iconePlay = "mdi-play-circle";
        this.desabilitarPlayer = false;
        clearTimeout(this.timeoutCarousel);
      }
    },
    velocidade() {
      if (!this.carouselAutomatico) {
        return;
      }

      clearTimeout(this.timeoutCarousel);
      this.moverCarousel();
    },
  },

  mounted() {
    this.leituraCarousel = this.leitura;

    if (!this.esconderCarousel) {
      this.buscarCarousel(this.leitura.id);
    }

    this.cortarImagem();
  },

  beforeDestroy() {
    clearTimeout(this.timeoutCarousel);
  },

  methods: {
    async buscarCarousel(leituraId) {
      try {
        this.carregandoCarousel = true
        this.carregandoImagem = true

        const dados = await apiLeituras.carrousel(leituraId, {
          limiteLateral: 10,
        });

        this.carousel = dados.data.sort((a, b) => a.id - b.id);

        this.carousel.map((item, index) => {
          if (item.id === leituraId) {
            this.carouselIndex = index;
          }
        });
      } catch (e) {
        this.$snackbar.mostrar({
          cor: "error",
          mensagem: "Erro ao carregar imagens para o vídeo",
        });
      } finally {
        this.carregandoCarousel = false;
        this.carregandoImagem = false
      }
    },

    async moverCarousel(voltar = false) {
      let index = voltar ? this.carouselIndex - 1 : this.carouselIndex + 1;
      if (typeof this.carousel[index] === "undefined") {
        await this.buscarCarousel(this.leituraCarousel.id);
        index = voltar ? this.carouselIndex - 1 : this.carouselIndex + 1;
      }

      if (this.carouselAutomatico) {
        clearTimeout(this.timeoutCarousel);
        this.timeoutCarousel = setTimeout(
          () => this.moverCarousel(this.carouselVoltar),
          this.velocidade * 1000
        );
      }

      if (typeof this.carousel[index] === "undefined") {
        return this.$snackbar.mostrar({
          cor: "",
          mensagem: "Nada para mostrar",
        });
      }

      this.leituraCarousel = this.carousel[index];
      this.carouselIndex = index;

      this.imagensErro = [];
      this.cortarImagem();
    },

    async iniciarPausarCarousel(voltar = false) {
      this.carouselAutomatico = !this.carouselAutomatico;
      this.carouselVoltar = voltar;
    },

    async placaAlterada(e) {
      this.mostrarDialogEditarPlaca = false;
      this.leitura.placa = e;
    },

    erroCarregarImagem(chave) {
      this.carregandoImagem = false;
      this.imagensErro.push(chave);
    },

    cortarImagem() {
      this.esconderRecorte = false;

      if (
        this.imagensErro.length > 0 ||
        this.leituraCarousel.imagens.length <= 0
      ) {
        this.esconderRecorte = true;
        return;
      }

      let imagemLeitura = null;
      this.leituraCarousel.imagens.map((i) => {
        if (i.placas && i.placas.length > 0 && imagemLeitura === null) {
          imagemLeitura = i;
        }
      });

      if (imagemLeitura === null || imagemLeitura.placas.length <= 0) {
        this.esconderRecorte = true;
        return;
      }

      if (!imagemLeitura.placas[0].posicao_placa) {
        this.esconderRecorte = true;
        return;
      }

      const caminhoImagem = this.caminhoImagemLeitura + imagemLeitura.caminho_imagem
      cortarPlaca(
        document.getElementById("canvas"),
        imagemLeitura.placas[0].posicao_placa,
        caminhoImagem
      );
    },
  },
};
</script>

<style>
.dialog-detalhes-leitura::-webkit-scrollbar {
  display: none;
}

.dialog-detalhes-leitura {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
